@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'bootstrap/dist/css/bootstrap.min.css';

/* START RESET */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
/* FIM RESET */

body {
    margin: 0;
    font-family: 'Barlow', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    margin-block: 0;
    margin-inline: 0;
}

body::-webkit-scrollbar {
    width: 12px;
    box-sizing: content-box;
}

body::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
    background-color: #c9c9c9; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    margin: 4px;
    border: 3px solid white;
    box-sizing: content-box;
}

html {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    min-height: 100%;
}

/* Editor do Diário de Bordo */
strong,
b {
    font-weight: bolder;
}
em {
    font-style: italic;
}
.ql-editor {
    min-height: 200px;
}

.desktop-only {
    display: inline-block;

    @media only screen and (max-width: 760px) {
        display: none;
    }
}

.mobile-only {
    display: none;

    @media only screen and (max-width: 760px) {
        display: inline-block;
    }
}

.slider-container-2 {
    width: 100%;
}

.disabled-button {
    background-color: rgb(201, 200, 200) !important; /* Cor cinza */
    color: rgb(165, 165, 165) !important; /* Cor do texto */
    border: none; /* Remove a borda, se necessário */
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader01 {
    width: fit-content;
    font-size: 18px;
    font-family: system-ui, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: #0000;
    -webkit-text-stroke: 1px #003c78;
    background: conic-gradient(#003c78 0 0) 50%/0 100% no-repeat text;
    animation: l4 1.5s linear infinite;
}
.loader:before {
    content: 'Loading';
}
@keyframes l4 {
    to {
        background-size: 120% 100%;
    }
}

.arrow-box {
    position: relative; /* Cria um contexto de posicionamento para o pseudo-elemento */
    background-color: #f0f0f0; /* Cor de fundo da div */
    width: 200px; /* Largura da div */
    z-index: 1;
}

.arrow-box::before {
    content: ''; /* Conteúdo vazio, necessário para o pseudo-elemento */
    position: absolute; /* Posicionamento absoluto em relação à div principal */
    top: 50%; /* Centraliza verticalmente o triângulo */
    left: 100%;
    transform: translateY(-50%); /* Ajusta a centralização vertical */
    border-width: 55px 15px; /* Largura das bordas do triângulo */
    border-style: solid; /* Estilo das bordas */
    border-color: transparent transparent transparent #f0f0f0; /* Define a cor do lado direito */
    z-index: -99;
}

.notch-box {
    position: relative; /* Cria um contexto de posicionamento para o pseudo-elemento */
    width: 200px; /* Largura da div */
    z-index: 1;
}

.notch-box2 {
    position: relative; /* Cria um contexto de posicionamento para o pseudo-elemento */
    width: 200px; /* Largura da div */
    z-index: 2;
}

/* HTML: <div class="loader"></div> */
.loaderCreater {
    width: 60px;
    aspect-ratio: 4;
    --_g: no-repeat radial-gradient(circle closest-side, #ffffff 90%, #0000);
    background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
    background-size: calc(100% / 3) 100%;
    animation: l7 1s infinite linear;
}
@keyframes l7 {
    33% {
        background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
    }
    50% {
        background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
    }
    66% {
        background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
    }
}

/* HTML: <div class="loader"></div> */
.loaderDelete {
    width: 20px;
    padding: 3px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #ffffff;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
}
@keyframes l3 {
    to {
        transform: rotate(1turn);
    }
}

.loaderConteudo {
    width: 20px;
    padding: 2px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #fafafa;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
}
@keyframes l3 {
    to {
        transform: rotate(1turn);
    }
}

.ant-slider-track {
    background: none !important;
}

/* Linha do slider */
.ant-slider-rail {
    background-color: #d9d9d9 !important; /* Cor da linha */
    margin-top: 20px;
    height: 2px !important; /* Ajusta a espessura */
}

/* Marcadores (pontos) do slider */
.ant-slider-dot {
    width: 40px !important;
    height: 40px !important;
}

.ant-slider-dot:hover {
    border: 1px solid #1890ff !important;
}

/* Personalização para o ponto ativo */
.ant-slider-dot-active {
    background-color: #1890ff !important; /* Cor do ponto ativo */
}

/* Remove a alça do slider */
.ant-slider-handle {
    display: none;
}

.ant-slider-mark-text {
    transform: translateY(32px) translateX(-60px) !important; /* Ajuste para mover o texto para baixo */
    display: flex;
    text-align: left;
    flex-wrap: wrap;
    width: 140px;
    font-size: 12px; /* (Opcional) Para ajustar o tamanho da fonte */
}

.ql-editor span[style*='background-color'] {
    padding: 8px; /* Adicione o valor desejado */
    display: inline-block; /* Garante que o fundo respeite o fluxo do texto */
    line-height: 1.4; /* Ajusta a altura da linha para alinhar bem com o restante do texto */
}

.ql-snow {
    .ql-picker {
        &.ql-size {
            .ql-picker-label,
            .ql-picker-item {
                &::before {
                    content: attr(data-value) !important;
                }
            }
        }
    }
}

.custom-progress .ant-progress-inner {
    width: 30px !important;
    height: 30px !important;
}
